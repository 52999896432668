import React from 'react';
import { connect } from 'react-redux';

import { ShowPopup, Button, Theme } from '@commonsku/styles';

import ErrorBoundary from '../components/ErrorBoundary';
import SelectFilePopup from '../components/SelectFilePopup';

const SelectFileApp = ({ user_id }) => {
  return (
    <ErrorBoundary>
      <Theme>
          <ShowPopup popup={SelectFilePopup} register="user_file_id" parent_id={user_id} parent_type="USER" resku={false} render={({ onClick }) => {
            return <Button id="select_file" type="button" size="small" onClick={onClick} style={{ display: 'none' }}>Select Files</Button>;
          }} />
      </Theme>
    </ErrorBoundary>
  );
};

const mapStateToProps = state => {
  return {
    user_id: state.identity.user_id
  };
};

export default connect(mapStateToProps, null)(SelectFileApp);